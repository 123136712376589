.wrapper {
    padding: 8px 15px;
    box-sizing: border-box;
    border: 1px solid #c5d8ec;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgb(239 236 236 / 50%);
}

.pac-container {
  z-index: 1300 !important;
}

body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  
  body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0,0,0,.25);
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
  }
  
  body ::-webkit-scrollbar-track {
    background: rgba(0,0,0,.1);
    border-radius: 0;
  }