.button-div {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

.button-div button {
  margin: 0 2px;
}

.list-table th {
  padding: 0 16px 16px 16px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  display: none;
}

.select-account {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #5b5757;
  text-align: center;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
}

.selected-count-wrapper {
  margin-top: 1rem;
}

.MuiTableCell-root {
  padding: 5px 16px !important;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.col-sm-12 {
  margin-top: 25px !important;
}

tr {
  background: #fff;
}

.tab-font-wrap {
  width: 150px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky-checkbox-header {
  position: sticky;
  left: 0 !important;
  z-index: 3 !important;
  background: inherit;
}
.sticky {
  position: sticky;
  left: 0;
  z-index: 1;
  background: inherit;
}

.sticky-header-name {
  position: sticky;
  left: 70px !important;
  z-index: 3 !important;
  background: inherit;
  border-right: 1px solid #eee;
}
.sticky-name {
  position: sticky;
  left: 70px;
  z-index: 1;
  background: inherit;
  border-right: 1px solid #eee;
}

.MuiTableRow-root:hover {
  background-color: #eeeeee !important;
}

.MuiTableRow-root:hover {
  background-color: #eeeeee;
}
